<template>
    <div>
        <Navbar 
            page="Ativos" 
            link="/cadastros" 
            nameLink="Cadastros" 
            link_2="/cadastros/riscos/" 
            nameLink_2="Gestão de riscos" 
        />
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <div class="mt-5 md:mt-0 md:col-span-2">
                <form action="#" method="POST">
                    <div class="flex flex-col gap-6 text-sm">
                        <section class="grid grid-cols-1 lg:grid-cols-7 gap-6">
                            <div class="col-span-1 lg:col-span-2 flex flex-col gap-1">
                                <label 
                                    for="name" 
                                    class=" font-bold">
                                    Nome do ativo
                                </label>
                                <input 
                                    v-model="form.nome" 
                                    type="text" 
                                    name="nome" 
                                    id="nome" 
                                    placeholder="xxxxxxxx"
                                    class="focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm border-gray-300 rounded-3xl"
                                >
                            </div> 
                            <div class="col-span-1 lg:col-span-3 flex flex-col gap-1">
                                <label 
                                    for="descricao" 
                                    class="font-bold">
                                    Descrição
                                </label>
                                <input 
                                    v-model="form.descricao" 
                                    type="text" 
                                    name="descricao" 
                                    id="descricao"
                                    placeholder="xxxxxxxx"
                                    class="focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:border-gray-300 rounded-3xl"
                                >
                            </div> 
                            <div class="col-span-1 lg:col-span-2 flex flex-col gap-1">
                                <label 
                                    for="considerarCanal" 
                                    class="font-bold">
                                    Considerar "Canal de tratamento"
                                </label>
                                <div class="flex gap-4">
                                    <section class="flex gap-2 items-center">
                                        <input 
                                            v-model="form.considerarCanal" 
                                            type="radio" 
                                            name="considerarCanalTrue" 
                                            id="considerarCanalTrue"
                                            class="rounded-3xl"
                                            :value="true"
                                        >
                                        <label for="considerarCanalTrue">Sim</label>
                                    </section>
                                    <section class="flex gap-2 items-center">
                                        <input 
                                            v-model="form.considerarCanal" 
                                            type="radio" 
                                            :value="false"
                                            name="considerarCanalFalse" 
                                            id="considerarCanalFalse"
                                            class="rounded-3xl"
                                        >
                                        <label for="considerarCanalFalse">Não</label>
                                    </section>
                                </div>
                            </div> 
                        </section>
                        <section class="grid grid-cols-1 lg:grid-cols-7 gap-6">
                            <div class="col-span-1 md:col-span-2 flex flex-col gap-1">
                                <label 
                                    for="categoria" 
                                    class=" font-bold">
                                    Categoria
                                </label>
                                <input 
                                    v-model="form.categoria" 
                                    type="text" 
                                    name="categoria" 
                                    id="categoria" 
                                    placeholder="xxxxxxxx"
                                    class="focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm border-gray-300 rounded-3xl"
                                >
                            </div>  
                            <div class="col-span-1 md:col-span-2 flex flex-col gap-1">
                                <label 
                                    for="subcategoria" 
                                    class="font-bold">
                                    Sub - Categoria
                                </label>
                                <input 
                                    v-model="form.subcategoria" 
                                    type="text" 
                                    name="subcategoria" 
                                    id="subcategoria" 
                                    placeholder="xxxxxxxx"
                                    class="focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm border-gray-300 rounded-3xl"
                                >
                            </div>
                            <div class="col-span-1 md:col-span-2 lg:col-span-3 flex flex-col gap-1">
                                <label 
                                    for="descricao" 
                                    class="font-bold">
                                    Responsável
                                </label>
                                <input 
                                    v-model="form.responsavel" 
                                    type="text" 
                                    name="responsavel" 
                                    id="responsavel"
                                    placeholder="xxxxxxxx"
                                    class="focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:border-gray-300 rounded-3xl"
                                >
                            </div>
                        </section>
                        <section class="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6">
                            <div class="flex flex-col gap-1 ">
                                <label 
                                    for="valorUnitario" 
                                    class=" font-bold">
                                    Valor unitário
                                </label>
                                <input 
                                    v-model="form.valorUnitario" 
                                    type="number" 
                                    name="valorUnitario" 
                                    id="valorUnitario" 
                                    placeholder="36,00"
                                    class="focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm border-gray-300 rounded-3xl"
                                >
                            </div>  
                            <div class="flex flex-col gap-1">
                                <label 
                                    for="moeda" 
                                    class="font-bold">
                                    Moeda
                                </label>
                                <input 
                                    v-model="form.moeda" 
                                    type="text" 
                                    name="moeda" 
                                    id="moeda" 
                                    placeholder="real, dolar, etc"
                                    class="focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm border-gray-300 rounded-3xl"
                                >
                            </div>
                            <div class="col-span-2 lg:col-span-1 grid grid-cols-3 gap-6">
                                <div class="flex flex-col gap-1 ">
                                    <label 
                                        for="quantidade" 
                                        class="font-bold">
                                        Quantidade
                                    </label>
                                    <input 
                                        v-model="form.quantidade" 
                                        type="number" 
                                        name="quantidade" 
                                        id="quantidade"
                                        placeholder="25"
                                        class="focus:ring-yellow-400 focus:border-yellow-400 block shadow-sm sm:border-gray-300 rounded-3xl"
                                    >
                                </div>
                                <div class="col-span-2 flex flex-col gap-1">
                                    <label 
                                        for="valorTotal" 
                                        class="font-bold">
                                        Valor total do ativo
                                    </label>
                                    <input 
                                        v-model="form.valorTotal" 
                                        type="number" 
                                        name="valorTotal" 
                                        id="valorTotal"
                                        placeholder="nnnnnnnnn"
                                        class="focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:border-gray-300 rounded-3xl"
                                    >
                                </div>
                            </div>
                        </section>
                        <section class="grid grid-cols-1 lg:grid-cols-2 gap-6">
                            <div class="flex flex-col">
                                <div class="flex items-center">
                                    <label
                                        for="vulnerabilidades" 
                                        class="font-bold">
                                        Possíveis vulnerabilidades
                                    </label>
                                    <svg
                                        @click="abrirModalVulnerabilidades()"
                                        fill="#059669"
                                        viewBox="0 0 256 256" 
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="mx-1 h-6 inline text-green-600"
                                    >
                                        <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                    </svg>
                                </div>
                                <div class="flex">
                                    <multiselect
                                        v-model="form.vulnerabilidades"
                                        :options="vulnerabilidades"
                                        :multiple="true"
                                        :close-on-select="false"
                                        label="nome"
                                        trackBy="_id"
                                        placeholder="selecione as vulnerabilidades"
                                        selectedLabel="selecionado"
                                        deselectLabel="Pressione Enter para remover"
                                        selectLabel="Pressione Enter para selecionar"
                                        class="mt-2"
                                        id="vulnerabilidades"
                                        @input="onSelectVulnerabilidade($event)"
                                    />
                                </div>
                            </div>
                            <div class="flex flex-col">
                                <div class="flex items-center">
                                    <label
                                        for="ameacas" 
                                        class="font-bold">
                                        Possíveis ameaças
                                    </label>
                                    <svg
                                        @click="abrirModalAmeacas()"
                                        fill="#059669"
                                        viewBox="0 0 256 256" 
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="mx-1 h-6 inline text-green-600"
                                    >
                                        <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                    </svg>
                                </div>
                                <div class="flex">
                                    <multiselect
                                        v-model="form.ameacas"
                                        :options="ameacas"
                                        :multiple="true"
                                        :close-on-select="false"
                                        label="nome"
                                        trackBy="_id"
                                        placeholder="selecione as ameaças"
                                        selectedLabel="selecionado"
                                        deselectLabel="Pressione Enter para remover"
                                        selectLabel="Pressione Enter para selecionar"
                                        class="mt-2"
                                        id="ameacas"
                                    />
                                </div>
                            </div>
                            <div class="flex flex-col">
                                <div class="flex items-center">
                                    <label
                                        for="controlesAdministrativos" 
                                        class="font-bold">
                                        Controles organizacionais / administrativos existentes
                                    </label>
                                    <svg
                                        @click="preencherControleModal('Organizacional')"
                                        fill="#059669"
                                        viewBox="0 0 256 256" 
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="mx-1 h-6 inline text-green-600"
                                    >
                                        <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                    </svg>
                                </div>
                                <div class="flex">
                                    <multiselect
                                        v-model="form.controlesAdministrativos"
                                        :options="controlesAdministrativos"
                                        :multiple="true"
                                        :close-on-select="false"
                                        label="nome"
                                        trackBy="_id"
                                        placeholder="selecione as controles administrativos"
                                        selectedLabel="selecionado"
                                        deselectLabel="Pressione Enter para remover"
                                        selectLabel="Pressione Enter para selecionar"
                                        class="mt-2"
                                        id="controlesAdministrativos"
                                    />
                                </div>
                            </div>
                            <div class="flex flex-col">
                                <div class="flex items-center">
                                    <label
                                        for="controlesTecnologos" 
                                        class="font-bold">
                                        Controles tecnológos existentes
                                    </label>
                                    <svg
                                        @click="preencherControleModal('Técnica')"
                                        fill="#059669"
                                        viewBox="0 0 256 256" 
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="mx-1 h-6 inline text-green-600"
                                    >
                                        <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                                    </svg>
                                </div>
                                <div class="flex">
                                    <multiselect
                                        v-model="form.controlesTecnologos"
                                        :options="controlesTecnologos"
                                        :multiple="true"
                                        :close-on-select="false"
                                        label="nome"
                                        trackBy="_id"
                                        placeholder="selecione as controles tecnológos"
                                        selectedLabel="selecionado"
                                        deselectLabel="Pressione Enter para remover"
                                        selectLabel="Pressione Enter para selecionar"
                                        class="mt-2"
                                        id="controlesTecnologos"
                                    />
                                </div>
                            </div>
                        </section>
                    </div>
                    
                    <div class="flex justify-between mt-4">
                        <button 
                            @click="$router.back()" 
                            type="button" 
                            class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                        >
                            Voltar
                        </button>
                        <button 
                            @click="save" 
                            type="button" 
                            class=" justify-center py-1 px-6 border border-transparent shadow-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" 
                            :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }"
                        >
                            Salvar
                        </button>
                    </div>
                    
                </form>
            </div>
        </div>
        <modal name="modalVulnerabilidades" :adaptive="true" :height="'auto'" :clickToClose="false">
            <div class="px-5 py-5">
                <h2 class="text-xl font-bold mb-4"> Nova Vulnerabilidade </h2>
                <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12">
                        <label for="novaVulnerabilidade" class="block text-sm font-medium">Nome</label>
                        <input
                            v-model="novoItem.nome"
                            type="text"
                            name="novaVulnerabilidade"
                            id="novaVulnerabilidade"
                            class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"
                        >
                    </div>
                </div>

                <div class="grid grid-cols-2 mt-3">
                <div class="py-3 text-left">
                    <button @click="$modal.hide('modalVulnerabilidades')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                        Voltar
                    </button>
                </div>
                <div class="py-3 text-right">
                    <button @click="adicionarVulnerabilidade" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                        Salvar
                    </button>
                </div>
            </div>
            </div>
        </modal>
        <modal name="modalAmeacas" :adaptive="true" :height="'auto'" :clickToClose="false">
            <div class="px-5 py-5">
                <h2 class="text-xl font-bold mb-4"> Nova ameaça </h2>
                <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12">
                        <label for="novaAmeaca" class="block text-sm font-medium">Nome</label>
                        <input
                            v-model="novoItem.nome"
                            type="text"
                            name="novaAmeaca"
                            id="novaAmeaca"
                            class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"
                        >
                    </div>
                </div>

                <div class="grid grid-cols-2 mt-3">
                    <div class="py-3 text-left">
                        <button @click="$modal.hide('modalAmeacas')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                            Voltar
                        </button>
                    </div>
                    <div class="py-3 text-right">
                        <button @click="adicionarAmeaca" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </modal>
        <modal name="modalControles" :adaptive="true" :height="'auto'" :clickToClose="false">
            <div class="px-5 py-5">
                <h2 class="text-xl font-bold mb-4"> Novo controle {{ this.novoItem.tipo }} </h2>
                <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12">
                        <label for="novoControle" class="block text-sm font-medium">Nome</label>
                        <input
                            v-model="novoItem.nome"
                            type="text"
                            name="novoControle"
                            id="novoControle"
                            class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"
                        >
                    </div>
                </div>

                <div class="grid grid-cols-2 mt-3">
                    <div class="py-3 text-left">
                        <button @click="$modal.hide('modalControles')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                            Voltar
                        </button>
                    </div>
                    <div class="py-3 text-right">
                        <button @click="adicionarControle" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            route: 'ativos',
            novoItem: {
                nome:'',
                tipo: null,
            },
            vulnerabilidades: [],
            ameacas: [],
            condutas: [],
            controlesAdministrativos: [],
            controlesTecnologos: [],
            form: {
                nome: '',
                descricao: null,
                categoria: null,
                subcategoria: null,
                responsavel: null,
                valorUnitario: null,
                considerarCanal: false,
                moeda: null,
                quantidade: null,
                valorTotal: null,
                vulnerabilidades: [],
                ameacas: [],
                controlesAdministrativos: [],
                controlesTecnologos: [],
            },
        }
    },
    methods: {
        async save() {
            const method = this.form._id ? 'put' : 'post'

            const req = await this.$http[method](`/v1/${this.route}`, this.form);

            if(!this.form.nome && !this.form.nome.trim()) return this.$vToastify.warning('Insira nome do ativo.');

            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.back();
            } else {
                this.$vToastify.error(req.data.err);
            }
        },
        async adicionarVulnerabilidade() {

            const req = await this.$http.post(`/v1/vulnerabilidades`, this.novoItem);
            if (req.data.success) {
                this.$vToastify.success("Vulnerabilidade adicionada com sucesso!");
                this.$modal.hide('modalVulnerabilidades');

                this.vulnerabilidades.push(req.data.data);
                this.form.vulnerabilidades.push(req.data.data);
            }else{
                const error = req.data && req.data.err ? req.data.err : `A vulnerabilidade já existe`
                return this.$vToastify.error(error);
            }
            this.novoItem.nome = '';
        },
        onSelectVulnerabilidade(itens) {

            itens?.forEach(item => {
                for (let i = 0; i < item.ameacas?.length; i++){
                    const ameaca = item.ameacas[i];
                    if (!this.form.ameacas?.some((a)=> a.nome == ameaca.nome)){
                        this.form.ameacas?.push(ameaca);
                    }
                }
                for (let i = 0; i < item.condutas?.length; i++){
                    const conduta = item.condutas[i];

                    if(conduta.tipo == 'Organizacional'){
                        if (!this.form.controlesAdministrativos?.some((c)=> c.nome == conduta.nome)){
                            this.form.controlesAdministrativos.push(conduta)
                        }
                    }else if(conduta.tipo == 'Técnica'){
                        if (!this.form.controlesTecnologos?.some((c)=> c.nome == conduta.nome)){
                            this.form.controlesTecnologos.push(conduta)
                        }
                    }
                }
            });
        },
        async adicionarAmeaca() {

            const req = await this.$http.post(`/v1/ameacas`, this.novoItem);
            if (req.data.success) {
                this.$vToastify.success("Ameaça adicionada com sucesso!");
                this.$modal.hide('modalAmeacas');
                const novo = req.data.data
                this.ameacas.push(novo);
                if (!this.form.ameacas) this.form.ameacas = []
                this.form.ameacas.push(novo);
            }else{
                const error = req.data && req.data.err ? req.data.err : `A Ameaça já existe`
                return this.$vToastify.error(error);
            }
            this.novoItem.nome = '';
        },
        async adicionarControle() {

            const req = await this.$http.post(`/v1/condutasmitigacao`, this.novoItem);
            if (req.data.success) {
                this.$vToastify.success("Controle adicionado com sucesso!");
                this.$modal.hide('modalControles');
                const novo = req.data.data
                if(novo.tipo == 'Organizacional'){
                    this.controlesAdministrativos.push(novo);
                    this.form.controlesAdministrativos.push(novo);
                }else{
                    this.controlesTecnologos.push(novo);
                    this.form.controlesTecnologos.push(novo);
                }
                
            }else{
                const error = req.data && req.data.err ? req.data.err : `O controle já existe`
                return this.$vToastify.error(error);
            }
            this.novoItem.nome = '';
            delete this.novoItem.tipo;
        },
        async preencherControleModal(tipo) {
            this.novoItem.nome = ''
            this.novoItem.tipo = tipo;

            await this.$modal.show('modalControles');
        },
        abrirModalAmeacas(){
            this.novoItem.nome = '';
            delete this.novoItem.tipo;
            this.$modal.show('modalAmeacas')
        },
        abrirModalVulnerabilidades(){
            this.novoItem.nome = '';
            delete this.novoItem.tipo;
            this.$modal.show('modalVulnerabilidades')
        }
    },
    async beforeMount() {
        const id = this.$route.params.id;

        const vulnerabilidades = await this.$http.post(`/v1/vulnerabilidades/list`, { all: true});
        this.vulnerabilidades = vulnerabilidades.data.data;

        const ameacas = await this.$http.post(`/v1/ameacas/list`, { all: true});
        this.ameacas = ameacas.data.data;

        const condutasAdm = await this.$http.post(`/v1/condutasmitigacao/list`, { 
            all: true,
            tipo: 'Organizacional'
        });
        this.controlesAdministrativos = condutasAdm.data.data;

        const condutasTec = await this.$http.post(`/v1/condutasmitigacao/list`, { 
            all: true,
            tipo: 'Técnica'
        });
        this.controlesTecnologos = condutasTec.data.data;

        if (id) {
            const req = await this.$http.get(`/v1/${this.route}/${id}`);
            this.form = req.data;
            console.log(this.form)
        }
    },
}
</script>